import { onActivated, ref } from 'vue';
import qs from 'qs';
import getPlaylistProgressQuery from '@/modules/shared/graphql/queries/getPlaylistProgressQuery.query.graphql';
import { useAsyncQuery } from '@/modules/shared/utils/apollo.util';
import MEDALS_QUERY from '@/graphql/queries/medals.query.graphql';
import http from '@/modules/shared/auth/services/http/strapiHttp.service';
import { createChallengeFromStrapiResponse } from '@/util/gamification/challenge';
import { useVueProxy } from '@/composables/useVueProxy';
import { createMedalQuery } from '@/util/gamification/medals';

export const useChallenge = (uuid = null, lazy = false) => {
  const { apollo } = useVueProxy();
  const challenge = ref();

  const fetch = async () => {
    const query = qs.stringify({
      populate: 'deep,8',
      filters: {
        uuid: {
          $eq: uuid,
        },
      },
    });

    const medalQuery = createMedalQuery(undefined, undefined, [
      {
        bronzeChallenge: {
          uuid: {
            $eq: uuid,
          },
        },
      },
      {
        silverChallenge: {
          uuid: {
            $eq: uuid,
          },
        },
      },
      {
        goldChallenge: {
          uuid: {
            $eq: uuid,
          },
        },
      },
    ]);

    const responses = await Promise.all([
      apollo.query({
        query: getPlaylistProgressQuery,
        variables: {
          playlistId: `challenge,${uuid}`,
        },
        fetchPolicy: 'no-cache',
      }),
      useAsyncQuery(MEDALS_QUERY, null, 0, 'no-cache'),
      http.get(`medals?${medalQuery}`),
      http.get(`challenges?${query}`),
    ]);

    const [playlistProgressResponse, medalProgressResponse, medalResponse, challengeResponse] =
      responses;

    challenge.value = createChallengeFromStrapiResponse(
      challengeResponse.data.data[0],
      playlistProgressResponse.data.playlist,
      medalResponse.data.data[0],
      null,
      medalProgressResponse.result.value?.medals ?? []
    );
  };

  onActivated(async () => {
    if (lazy) return;
    await fetch();
  });

  return {
    challenge,
    fetch,
  };
};
