import { render, staticRenderFns } from "./MyMedalsSection.vue?vue&type=template&id=1f52e600&scoped=true"
import script from "./MyMedalsSection.vue?vue&type=script&lang=js"
export * from "./MyMedalsSection.vue?vue&type=script&lang=js"
import style0 from "./MyMedalsSection.vue?vue&type=style&index=0&id=1f52e600&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f52e600",
  null
  
)

/* custom blocks */
import block0 from "./MyMedalsSection.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports