<script>
  import { computed, defineComponent, onActivated, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router/composables';
  import DefaultSpacer from '@/modules/shared/components/default-spacer/DefaultSpacer.vue';
  import { JumButton, JumCollapsible, JumHeading, JumIcon } from '@blancofoodcoach/kompas';
  import ExtraSupport from '@/modules/home/components/extra-support/ExtraSupport.vue';
  import ProgressBar from '@/components/library/progress-bar/ProgressBar.vue';
  import LinkCardList from '@/components/library/link-card-list/LinkCardList.vue';
  import UPDATE_USER_PROFILE_MUTATION from '@/modules/shared/graphql/mutations/updateUserProfile.mutation.graphql';
  import { useVueProxy } from '@/composables/useVueProxy';
  import addPlaylistProgressMutation from '@/modules/shared/graphql/mutations/addPlaylistProgress.mutation.graphql';
  import { useI18n } from '@/modules/core/composables/useI18n';
  import resetPlaylistProgressMutation from '@/modules/shared/graphql/mutations/resetPlaylistProgress.mutation.graphql';
  import { useMutation } from '@/modules/shared/utils/apollo.util';
  import ADD_MEDAL_MUTATION from '@/graphql/mutations/addMedal.mutation.graphql';
  import DomPurify from '@/components/dom-purify/DomPurify.vue';
  import MedalPopup from '@/components/medal-popup/MedalPopup.vue';
  import { Portal } from 'portal-vue';
  import { useProfile } from '@/modules/planner/composables/useProfile';
  import { useChallenge } from '@/composables/useChallenge';

  export default defineComponent({
    name: 'ChallengeDetailNoPlaylistPage',
    components: {
      Portal,
      MedalPopup,
      DomPurify,
      JumButton,
      LinkCardList,
      ProgressBar,
      JumCollapsible,
      ExtraSupport,
      JumHeading,
      DefaultSpacer,
      JumIcon,
    },
    setup() {
      const { t } = useI18n();
      const { apollo, modaly } = useVueProxy();
      const router = useRouter();
      const route = useRoute();
      const { uuid } = route.params;
      const { challenge, fetch: fetchChallenge } = useChallenge(uuid, true);
      const { user, fetch: refetch, hasFlag } = useProfile('network-only');
      const showMedals = computed(() => hasFlag('medals'));
      const isFocus = computed(() => user.value?.playlistId === `challenge,${uuid}`);

      const mutatePlaylistId = async newValue => {
        await apollo.mutate({
          mutation: UPDATE_USER_PROFILE_MUTATION,
          variables: { updateUserProfileInput: { playlistId: newValue } },
        });
      };

      const addMedal = async (medalId, score) => {
        useMutation(ADD_MEDAL_MUTATION, {
          medalId,
          score,
        });
      };

      const medalToReward = computed(() => {
        const { uuid: challengeUuid, medal } = challenge.value;
        if (!medal) return null;

        const medalTypes = ['bronze', 'silver', 'gold'];
        const foundType = medalTypes.find(type => medal[type]?.challenge?.uuid === challengeUuid);

        return medal[foundType] || null;
      });

      const isOpenMedalPopup = ref(false);

      onActivated(async () => {
        await refetch();
        await fetchChallenge();

        if (
          showMedals.value &&
          challenge.value.completed &&
          medalToReward.value !== null &&
          !medalToReward.value.isCompleted
        ) {
          await addMedal(challenge.value.medal.uuid, medalToReward.value.name.toUpperCase());
          await mutatePlaylistId(null);
          await refetch();
          isOpenMedalPopup.value = true;
        }
      });

      const steps = computed(() => {
        let opened = false;
        return challenge.value.steps.map(step => {
          const isCompleted = step.items.every(link => link.completed);
          let isOpen = false;

          if (!isCompleted && !opened) {
            isOpen = true;
            opened = true;
          }
          return {
            ...step,
            isCompleted,
            isOpen,
          };
        });
      });

      const addProgress = async (id, itemId) => {
        await apollo.mutate({
          mutation: addPlaylistProgressMutation,
          variables: {
            playlistId: id,
            itemId,
          },
        });
      };

      const handleItemSelected = async item => {
        if (item.tag.type === 'learning') {
          await addProgress(`challenge,${uuid}`, item.uuid);
          await fetchChallenge();
        }
      };

      const resetPlaylistProgress = async playlistId => {
        await apollo.mutate({
          mutation: resetPlaylistProgressMutation,
          variables: {
            playlistId,
          },
        });
      };

      const startOver = async () => {
        if (challenge.value.inProgress) {
          modaly({
            title: t('modal.title'),
            message: t('modal.message'),
            confirmText: t('modal.continueProgressButton'),
            cancelText: t('modal.startOverButton'),
            onConfirm: async modal => {
              // Progress
              await mutatePlaylistId(`challenge,${uuid}`);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
            onCancel: async modal => {
              // Start over
              await resetPlaylistProgress(`challenge,${uuid}`);
              await mutatePlaylistId(`challenge,${uuid}`);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
          });
        } else {
          await mutatePlaylistId(`challenge,${uuid}`);
          await router.push({
            name: 'home',
          });
        }
      };

      const focus = async () => {
        if (user.value?.playlistId) {
          await modaly({
            title: t('modalOtherFocus.title'),
            message: t('modalOtherFocus.message'),
            confirmText: t('modalOtherFocus.confirm'),
            cancelText: t('modalOtherFocus.cancel'),
            onConfirm: async modal => {
              await startOver();
              modal.close();
            },
            onCancel: async modal => {
              modal.close();
            },
          });
        } else {
          await startOver();
        }
      };

      const unfocus = async () => {
        await mutatePlaylistId(null);
        await router.push({
          name: 'home',
        });
      };

      const checkIfAllowed = () => {
        if (!isFocus.value) {
          modaly({
            title: t('modal2.title'),
            confirmText: t('modal2.confirmButton'),
            cancelText: t('modal2.cancelButton'),
            onConfirm: async modal => {
              await mutatePlaylistId(`challenge,${uuid}`);
              await router.push({
                name: 'home',
              });
              modal.close();
            },
            onCancel: async modal => {
              modal.close();
            },
          });
        }
      };

      const closePopup = () => {
        isOpenMedalPopup.value = false;
      };

      return {
        challenge,
        handleItemSelected,
        focus,
        unfocus,
        isFocus,
        checkIfAllowed,
        steps,
        medalToReward,
        isOpenMedalPopup,
        closePopup,
      };
    },
  });
</script>

<template>
  <fc-page v-if="challenge">
    <template #top>
      <fc-nav-bar>
        {{ $t('title') }}
      </fc-nav-bar>
    </template>

    <fc-page-content :class="{ [challenge.tag]: true }">
      <div class="image">
        <img :src="challenge.coverImageUrl" :alt="`Image for ${challenge.title}`" />
      </div>

      <default-spacer s />

      <div class="h-group">
        <jum-heading h3 bold class="title">
          {{ challenge.title }}
        </jum-heading>
      </div>

      <p class="body">
        {{ challenge.description }}
      </p>

      <section v-if="challenge.completed && challenge.takeHomeMessages" class="take-home">
        <jum-heading h4 bold>
          {{ $t('takeHomeMessageTitle') }}
        </jum-heading>

        <dom-purify :html="challenge.takeHomeMessages" />
      </section>

      <default-spacer />

      <progress-bar
        :current-step="challenge.completedItems"
        :total-steps="challenge.totalItems"
        :progress="challenge.progressForItems"
      />

      <default-spacer />

      <template v-if="challenge.hasSteps">
        <jum-collapsible v-for="(step, index) in steps" :key="`step-${index}`" :open="step.isOpen">
          <template #title>
            <div class="step-title">
              <template v-if="step.isCompleted">
                <div class="dot completed">
                  <jum-icon icon="jum-check" size="12" />
                </div>
              </template>
              <template v-else>
                <div class="dot">
                  {{ index + 1 }}
                </div>
              </template>

              <jum-heading h5 bold>
                {{ step.title }}
              </jum-heading>
            </div>
          </template>

          <link-card-list
            class="clickable"
            :links="step.items"
            :is-disabled="!isFocus"
            @item-selected="handleItemSelected"
            @click.native="checkIfAllowed"
          />

          <default-spacer s />
        </jum-collapsible>

        <default-spacer />
      </template>

      <extra-support v-if="challenge.showExtraSupportSection" />
    </fc-page-content>

    <template #bottom>
      <fc-action-bar>
        <jum-button v-if="!isFocus" block @click="focus">
          {{ $t('focusButton') }}
        </jum-button>

        <jum-button v-else block secondary @click="unfocus">
          {{ $t('unfocusButton') }}
        </jum-button>
      </fc-action-bar>
    </template>

    <portal v-if="medalToReward" to="overlay">
      <medal-popup
        :medal="medalToReward"
        :is-open="isOpenMedalPopup"
        @proceed="closePopup"
        @cancel="closePopup"
      />
    </portal>
  </fc-page>
</template>

<style scoped lang="scss">
  @import '~@/styles/global.scss';

  .step-title {
    align-items: center;
    display: flex;
    gap: $spacing-s;

    .jum-heading {
      margin: 0;
    }

    .dot {
      align-items: center;
      background-color: #e3e3e3;
      border-radius: 50%;
      display: flex;
      font-size: 14px;
      font-weight: 300;
      height: 20px;
      justify-content: center;
      width: 20px;

      &.completed {
        background-color: #0d8933;

        svg {
          fill: $color-white;
        }
      }
    }
  }

  .image {
    overflow: hidden;

    img {
      border-radius: 16px;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .body {
    font-size: 1rem !important;
    font-weight: 300 !important;
    line-height: 1rem * 1.3 !important;
  }

  .h-group {
    display: flex;
    flex-direction: column;
    gap: 2px;
    position: relative;
    z-index: 1;

    > * {
      margin: 0;
    }
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: $spacing-s;

    .fc-numbered-list-item {
      margin-top: 0;
    }
  }

  .clickable {
    pointer-events: all;
  }

  .perform {
    :deep(.progress-bar) {
      border-color: $taf-red;

      .progress-bar__fill {
        background-color: #feedec;
      }
    }

    :deep(.tooltip) {
      background-color: $taf-red;

      &::after {
        border-color: $taf-red transparent transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: $taf-red;
        }
      }
    }
  }

  .recover {
    :deep(.progress-bar) {
      border-color: #ffb736;

      .progress-bar__fill {
        background-color: #fff6e7;
      }
    }

    :deep(.tooltip) {
      background-color: #ffb736;

      &::after {
        border-color: #ffb736 transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #ffb736;
        }
      }
    }
  }

  .protect {
    :deep(.progress-bar) {
      border-color: $taf-green;

      .progress-bar__fill {
        background-color: #e7fded;
      }
    }

    :deep(.tooltip) {
      background-color: $taf-green;

      &::after {
        border-color: $taf-green transparent transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: $taf-green;
        }
      }
    }
  }

  .coach {
    :deep(.progress-bar) {
      border-color: #908ac0;

      .progress-bar__fill {
        background-color: #f1f0f7;
      }
    }

    :deep(.tooltip) {
      background-color: #908ac0;

      &::after {
        border-color: #908ac0 transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #908ac0;
        }
      }
    }
  }

  .specials {
    :deep(.progress-bar) {
      border-color: #11aeed;

      .progress-bar__fill {
        background-color: #feffff;
      }
    }

    :deep(.tooltip) {
      background-color: #11aeed;

      &::after {
        border-color: #11aeed transparent transparent;
      }
    }

    :deep(.step-title) {
      .dot {
        &.completed {
          background-color: #11aeed;
        }
      }
    }
  }

  .take-home {
    padding-top: 10px;

    :deep(.jum-heading) {
      margin-bottom: 10px;
    }

    span {
      :deep(ul),
      :deep(ol) {
        margin: 10px;
        padding-left: 20px;

        li:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }

      :deep(p) {
        line-height: 1.4rem;
        white-space: pre-line;
      }

      :deep(*):last-child {
        margin-bottom: 0;
      }
    }
  }
</style>

<i18n>
{
  "en": {
    "title": "Challenge",
    "focusButton": "Start challenge",
    "unfocusButton": "Stop challenge",
    "modal": {
      "title": "We’ve noted progress you’ve made earlier",
      "message": "Would you like to continue were you’ve been or start this challenge all over again?",
      "continueProgressButton": "Continue progress",
      "startOverButton": "Start over"
    },
    "modal2": {
      "title": "Start this focus plan to check the details of this challenge",
      "confirmButton": "Start challenge",
      "cancelButton": "Cancel"
    },
    "modalOtherFocus": {
      "title": "Are you sure you want to change your focus?",
      "message": "Another plan or challenge is currently in your focus. If you choose to continue, we will change focus to this challenge",
      "confirm": "Yes, continue",
      "cancel": "No, cancel"
    },
    "takeHomeMessageTitle": "Take-home messages"
  },
  "nl": {
    "title": "Uitdaging",
    "focusButton": "Start uitdaging",
    "unfocusButton": "Stop uitdaging",
    "modal": {
      "title": "We hebben gemerkt dat je eerder al bezig was met deze uitdaging",
      "message": "Wil je verder gaan waar je gebleven was of wil je opnieuw beginnen?",
      "continueProgressButton": "Doorgaan",
      "startOverButton": "Opnieuw beginnen"
    },
    "modal2": {
      "title": "Start deze uitdaging om de details van deze uitdaging te bekijken",
      "confirmButton": "Start uitdaging",
      "cancelButton": "Annuleren"
    },
    "modalOtherFocus": {
      "title": "Weet je zeker dat je je focus wilt wijzigen?",
      "message": "Er is momenteel een ander plan of uitdaging in je focus. Als je doorgaat, zullen we de focus veranderen naar deze uitdaging",
      "confirm": "Ja, doorgaan",
      "cancel": "Nee, annuleren"
    },
    "takeHomeMessageTitle": "Take-home messages"
  }
}
</i18n>
