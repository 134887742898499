import { render, staticRenderFns } from "./ChallengeDetailNoPlaylistPage.vue?vue&type=template&id=2e49033b&scoped=true"
import script from "./ChallengeDetailNoPlaylistPage.vue?vue&type=script&lang=js"
export * from "./ChallengeDetailNoPlaylistPage.vue?vue&type=script&lang=js"
import style0 from "./ChallengeDetailNoPlaylistPage.vue?vue&type=style&index=0&id=2e49033b&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e49033b",
  null
  
)

/* custom blocks */
import block0 from "./ChallengeDetailNoPlaylistPage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports